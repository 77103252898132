'use strict';

angular.module('webPartnerPortalApp')
  .controller('PartnerNewCampaignModalCtrl', ['credentials', 'channels', '$http', '$modalInstance', function(credentials, channels, $http, $modalInstance) {
    this.credentials = credentials;
    var vm = this;
    vm.errorMsg = '';
    vm.capitalizeFirstLetter = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    vm.channels = channels;
    vm.channel = '';

    this.reset = function () {
      this.credentials.ref_ac = '';
      this.credentials.ref_mc = '';
      this.credentials.is_abp_eligible = false;
      this.credentials.refac_refmc_name = '';
      this.credentials.start_date = '';
      this.credentials.end_date = '';
    };

    this.submit = function () {
      if(['DirectMail', 'ExternalMail'].indexOf(this.channel.name_unformatted) === -1){
        delete this.credentials.start_date;
        delete this.credentials.end_date;
        delete this.credentials.refac_refmc_name;
      }
      if(['Affiliates', 'QualifiedPartners', 'WarmTransfer'].indexOf(this.channel.name_unformatted) === -1){
        delete this.credentials.is_abp_eligible;
      }
      var params = angular.extend({}, this.credentials);
      if(typeof vm.channel.name_unformatted !== 'undefined'){
        params.campaign_channel_name = vm.channel.name_unformatted;
      }
      if(typeof vm.credentials.start_date !== 'undefined'){
        params.start_date = moment(vm.credentials.start_date, 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm:ss +HHmm');
      }
      if(typeof vm.credentials.start_date !== 'undefined'){
        params.end_date = moment(vm.credentials.end_date, 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm:ss +HHmm');
      }
      $http.post('api/partners/refacs', params)
        .success(function(res){
          $modalInstance.close(res.body);
        })
        .error(function(err){
           vm.errorMsg = vm.capitalizeFirstLetter(err.body.message);
        });
    };
    this.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }])
.directive('checkIfPastDate', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ctrl) {
      ctrl.$validators.pastDate = function (modelValue, viewValue) {
        if(!moment(viewValue, 'MM/DD/YYYY', true).isValid()){
          return true;
        }
        else if(moment(viewValue, 'MM/DD/YYYY').isSameOrAfter(moment(), 'days')){
          return true;
        }
        return false;
      };
    }
  };
})
  .directive('dateGreaterThan', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        ctrl.$validators.lessThanStartDate = function (modelValue, viewValue) {
          if(!moment(viewValue, 'MM/DD/YYYY', true).isValid()){
            return true;
          }
          else if(moment(viewValue, 'MM/DD/YYYY').isAfter(moment(scope.vm.credentials.start_date, 'MM/DD/YYYY'), 'days')){
            return true;
          }
          return false;
        };
      }
    };
  });
